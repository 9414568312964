import React, { Component } from 'react'
import CountUp from 'react-countup';
export class Card4 extends Component {
  getCount(count){
    const check  = count.includes("+");
    if(check){
      return +count.split('+')[0]
    }else{
      return count
    }
  }

  getPlus(count){
    const check  = count.includes("+");
    if(check){
      return "+"
    }else{
      return ''
    }
  }
  render() {
    const {title, sub_title="", count, bg="bg-[var(--white2)]"} = this.props;
    return (
        <div className={` w-[268px] h-[230px] flex flex-col justify-between gap-[8px]  max-[720px]:m-auto shadow shadow-[0px_4px_16px_0px_#00000040]  items-center rounded-[8px] ${bg}  p-[50px] `}>
           <div className='flex flex-col'>
              <span className='font-[900] text-[12px] text-[var(--gray)]  text-center inline-flex'>{title}</span>
              {
                sub_title !=""?
                <span className='font-[400] text-[12px]  text-center text-[var(--gray)] w-[70px]'>{sub_title}</span>:null
              }
           </div>
            <span className='font-[700] text-[48px] text-[var(--blue)] '>
              {/* {count} */}
              <CountUp duration={5} end={this.getCount(count)} />
              {this.getPlus(count)}
            </span>
      </div>
    )
  }
}

export default Card4