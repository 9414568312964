import React, { Component } from 'react'
import SectionHeader from '../SectionHeader'
import Card1 from '../cards/Card1'
import { connect } from 'react-redux';
import { getWhyUs } from '../../actions/MainAction';
export class WhyUsSection extends Component {
  componentDidMount(){
    this.props.getWhyUs(this.props.lang_id);
  }
  render() {
    const {whyUsData, lang} = this.props;
    return (
        <section className=' flex flex-col  gap-[16px] pt-[52px]'>
        <SectionHeader title={lang?.why_us} />
           <div className='container'>
            <span className='text-[var(--black)] pl-[16px] leading-[24px] text-[16px] font-[400]'>
                {lang?.why_us_title}
            </span>
    
            <div className='grid grid-cols-3 mt-[32px] gap-[32px] max-[960px]:grid-cols-2 max-[670px]:grid-cols-1'>
              {
                whyUsData?.map((data, i)=>{
                  return(
                    <Card1 key={i} data={data} />
                  )
                })
              }
            </div>
           </div>
         </section>
 
    )
  }
}
const mapStateToProps = (state)=>({
  whyUsData: state.Data.whyUsData,
  lang_id: state.Data.lang_id,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getWhyUs}
export default connect(mapStateToProps, mapDispatchToProps)(WhyUsSection)