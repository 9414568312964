import React, { Component } from 'react'
import Card5 from '../cards/Card5'
import { getPartnerData } from '../../actions/MainAction';
import { connect } from 'react-redux';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export class MainPartnerSliderSection extends Component {
  componentDidMount(){
    this.props.getPartnerData(this.props.lang_id);
  }
  render() {
    const {partnerData} = this.props;
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 3200, min: 1800 },
        items: 12
      },
      desktop: {
        breakpoint: { max: 1800, min: 1024 },
        items: 7
      },
      tablet: {
        breakpoint: { max: 1024, min: 700 },
        items: 4
      },
      mobile: {
        breakpoint: { max: 700, min: 400 },
        items: 3
      },
      mobile2: {
        breakpoint: { max: 400, min: 300 },
        items: 2
      }
    };
    return (
        <section className='pt-[52px] '>
       
          <Carousel 
                swipeable={true}
                draggable={false}
                
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={2000}
                keyBoardControl={false}
                customTransition="all 2s"
                transitionDuration={2000}
                containerClass="carousel-container2"
              //   deviceType={this.props.deviceType}
                
              >
            {
              partnerData?.map((data, i)=>{
                return(
                  <Card5 key={i} image={data?.image_full_url} />
                )
              })
            }
             </Carousel>
      
        </section>
    )
  }
}

const mapStateToProps = (state)=>({
  partnerData: state.Data.partnerData,
  lang_id: state.Data.lang_id,
});
const mapDispatchToProps = {getPartnerData}
export default connect(mapStateToProps, mapDispatchToProps)(MainPartnerSliderSection)