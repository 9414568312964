import React, { Component } from 'react'
import YellowBtn from '../../about_us_component/YellowBtn'
import { connect } from 'react-redux';
import { widthHooks } from '../../actions/withHooks';

export class SliderCard extends Component {
    state={
        valueWidth:0
    }
    redirectPage=(index)=>{
        localStorage.setItem("redirectSlide", index)
        this.props.navigate("/service")
       
    }
    componentDidMount(){
        this.getLineWidth();
        // console.log("sss")
    }
    getLineWidth(){
        //  let x = 0;
        //  let interval = setInterval(()=>{
        //   x+=2;
        //   this.setState({valueWidth:x})
        //   if(x >= 98){
        //     x=0;
        //     clearInterval(interval)
        //     this.setState({valueWidth:0})
        //   }
        //   // console.log(x)
        //  }, 100);
        }
  render() {
    const {data, lang} = this.props;
    const {valueWidth} = this.state;
    return (
        <div id={data?.id} className='bg-[var(--white)] relative rsp-header flex justify-center h-[70vh] max-[700px]:h-[50vh] max-[500px]:h-[38vh] '>
            {
                data?.image_full_url !=""?
                <img className=' left-0 top-0 w-full h-full absolute' src={data?.image_full_url} />:
                <video controls className=' left-0 top-0 w-full h-full absolute' src={data?.video_full_url} />
            }
            
            <div className='container  relative '>
            <h1 className='text-[var(--white)] text-[48px] max-w-[420px] font-[900] leading-[62px] max-[450px]:text-[24px] max-[450px]:leading-[34px]'>
            {data?.translate?.title}
            </h1>
            <p className='text-[var(--white)] text-[20px] max-w-[430px] font-[500] pt-[12px] leading-[28px] max-[450px]:text-[16px] max-[450px]:leading-[20px]' dangerouslySetInnerHTML={{ __html: data?.translate?.text }}></p>
            {
            data?.path && data?.path !="" || data?.path ==0?
            <YellowBtn onClick={this.redirectPage.bind(this, data?.path)} text={lang?.btn_learn_more} />:null
            }
            {/* {this.getLineWidth()} */}
            {/* <div className='max-w-[520px] flex items-center mt-[30px]'>
            <span style={{ width:`${valueWidth}%`, transition: 'width 0.1s' }} className={`h-[1px]  bg-white`}></span>
            <span className='h-[5px] w-[5px] rounded-full bg-white'></span>
            </div> */}
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang: state.Data.lang
});
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(widthHooks(SliderCard))