import React, { Component } from 'react'
import Card8 from '../cards/Card8'
import Card9 from '../cards/Card9'
import SectionHeader from '../SectionHeader'

export class ServiceSection extends Component {
  render() {
    const {data} = this.props;
    return (
        <section  id={data?.id} className=' pt-[52px] service-section'>
            <SectionHeader title={data?.translate?.title} image={data?.svg_full_url} />
            <div className='container'>
                <div className='pt-[40px]' >
                    <h3 className='text-[var(--red)] font-[300] text-[16px]'>{data?.translate?.sub_title}</h3>
                    <div className='pt-[16px] flex gap-[16px]  flex-wrap items-center max-[405px]:justify-center'>
                        {
                            data?.categories?.map((item, j)=>{
                                return(
                                    <Card8 key={j} item={item} />
                                )
                            })
                        }
                    </div>

                    <div className='pt-[52px] flex flex-col gap-[24px]'>
                        <div className='grid grid-cols-3 gap-[32px] items-center justify-between max-[1000px]:grid-cols-2 max-[700px]:grid-cols-1 '>
                            {
                                data?.images?.map((item, j)=>{
                                    return(
                                       <Card9 key={j} image={item?.image_full_url} />  
                                    )
                                })
                            }
                        </div>
                        <div className=' flex flex-col gap-[16px] p-[8px_16px]'>
                            <div className='font-[300] text-[var(--black)] text-[16px] list' dangerouslySetInnerHTML={{ __html:data?.translate?.description }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
  }
}

export default ServiceSection