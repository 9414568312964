import React, { Component } from 'react'
import Card4 from '../cards/Card4'
import { connect } from 'react-redux';
import { getCounterMain } from '../../actions/MainAction';
export class MainCounterSection extends Component {
  componentDidMount(){
    this.props.getCounterMain(this.props.lang_id);
  }
  render() {
    const {mainCounterData} = this.props;
    // console.log(mainCounterData)
    return (
        <div className='grid grid-cols-3 gap-[82px]  pt-[52px] items-center justify-center m-[0px_84px] max-[450px]:m-[0px] max-[750px]:gap-[60px]  max-[960px]:grid-cols-2 max-[720px]:grid-cols-1'>
            {
              mainCounterData?.map((data, i)=>{
                return(
                  <Card4 title={data?.translate?.title} sub_title={data?.translate?.sub_title} count={data?.count} key={i} />
                )
              })
            }
        </div>  
    )
  }
}

const mapStateToProps = (state)=>({
  mainCounterData: state.Data.mainCounterData,
  lang_id: state.Data.lang_id,
});
const mapDispatchToProps = {getCounterMain}
export default connect(mapStateToProps, mapDispatchToProps)(MainCounterSection)