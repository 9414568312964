import React, { Component } from 'react'
import boy from '../images/boy-img.png'
import YellowBtn from './YellowBtn'
import logoWhite from '../images/logoWhite.png'
import Nav from './Nav'
import Footer from './Footer'
import SectionHeader from '../main_components/SectionHeader'
import MainSliderCard from '../main_components/MainSliderCard'
import SliderDots from '../main_components/SliderDots'
import WhyUsSection from '../main_components/sections/WhyUsSection'
import DirectionSection from '../main_components/sections/DirectionSection'
import SpesiphicBlogs from '../main_components/sections/SpesiphicBlogs'
import ProjectsSection from '../main_components/sections/ProjectsSection'
import MainPartnerSliderSection from '../main_components/sections/MainPartnerSliderSection'

export class AboutUs extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    return (
      <div className='bg-[var(--white2)] pt-[0.1px]'>
        <header>
          <Nav />
          <MainSliderCard />
          {/* <SliderDots /> */}
        </header>
        <WhyUsSection />
        <DirectionSection />
        <SpesiphicBlogs />
        <ProjectsSection />
        <MainPartnerSliderSection />
        <Footer />
      </div>
    )
  }
}

export default AboutUs