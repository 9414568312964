import React, { Component } from 'react'
import YellowBtn from '../about_us_component/YellowBtn'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connect } from 'react-redux';
import { getMainSlider } from '../actions/MainAction';
import { useNavigate } from 'react-router-dom';
import SliderCard from './cards/SliderCard';
function widthHooks(Component){
  return props => <Component {...props} navigate={useNavigate()}  />
}
export class MainSliderCard extends Component {
  state={
    valueWidth:0
  }
  componentDidMount(){
    this.props.getMainSlider(this.props.lang_id)
    this.getLineWidth()
  }

  redirectPage=(index)=>{
    localStorage.setItem("redirectSlide", index)
    this.props.navigate("/service")
   
  }
  getLineWidth(){
  //  let x = 0;
  //  let interval = setInterval(()=>{
  //   x+=2;
  //   this.setState({valueWidth:x})
  //   if(x >= 98){
  //     x=0;
  //     // clearInterval(interval)
  //     this.setState({valueWidth:0})
  //   }
  //   // console.log(x)
  //  }, 100);
  }
  render() {
    // 
    const {mainSliderData, lang} = this.props;
    const {valueWidth} = this.state;
    // console.log(valueWidth)
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 564 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 564, min: 0 },
        items: 1
      }
    };
    return (
      <Carousel 
      swipeable={true}
      draggable={false}
      showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={5000}
      keyBoardControl={true}
      infiniteLoop={true}
      emulateTouch={true}
      containerClass="carousel-container"
    //   deviceType={this.props.deviceType}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
      // showThumbs={false}
      selectedItem={0}
      firstItem={0}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      centerMode={false}
      
    >
      {
        mainSliderData?.map((data, i)=>{
          return (
          //   <div key={i} className='bg-[var(--white)] relative rsp-header flex justify-center h-[70vh] max-[700px]:h-[50vh] max-[500px]:h-[38vh] '>
          //     {
          //         data?.image_full_url !=""?
          //         <img className=' left-0 top-0 w-full h-full absolute' src={data?.image_full_url} />:
          //         <video controls className=' left-0 top-0 w-full h-full absolute' src={data?.video_full_url} />
          //     }
              
          //     <div className='container  relative '>
          //     <h1 className='text-[var(--white)] text-[48px] max-w-[420px] font-[900] leading-[62px] max-[450px]:text-[24px] max-[450px]:leading-[34px]'>
          //        {data?.translate?.title}
          //     </h1>
          //     <p className='text-[var(--white)] text-[20px] max-w-[430px] font-[500] pt-[12px] leading-[28px] max-[450px]:text-[16px]' dangerouslySetInnerHTML={{ __html: data?.translate?.text }}></p>
          //     {
          //      data?.path && data?.path !="" || data?.path ==0?
          //       <YellowBtn onClick={this.redirectPage.bind(this, data?.path)} text={lang?.btn_learn_more} />:null
          //     }
          //     {/* {this.getLineWidth()} */}
          //     {/* <div className='max-w-[520px] flex items-center mt-[30px]'>
          //       <span style={{ width:`${valueWidth}%`, transition: 'width 0.1s' }} className={`h-[1px]  bg-white`}></span>
          //       <span className='h-[5px] w-[5px] rounded-full bg-white'></span>
          //     </div> */}
          //     </div>
          // </div>
          <SliderCard key={i} data={data} />
          )
        })
      }
        
        </Carousel>
    )
  }
}
const mapStateToProps = (state) =>({
  mainSliderData: state.Data.mainSliderData,
  lang_id: state.Data.lang_id,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getMainSlider}
export default connect(mapStateToProps, mapDispatchToProps)(widthHooks(MainSliderCard))