import React, { Component } from 'react'
import Nav from '../about_us_component/Nav'
import despro from '../images/despro.png'
import handBig from "../images/hands_big.png"
import BigImg from '../main_components/BigImg'
import { connect } from 'react-redux'
import { getCostumerData, getCostumerReviews,getPartnerData } from '../actions/MainAction'
import Card5 from '../main_components/cards/Card5'
import YellowBtn from '../about_us_component/YellowBtn'
import Footer from '../about_us_component/Footer'
import Card10 from '../main_components/cards/Card10'
import { widthHooks } from '../actions/withHooks'
export class Customer extends Component {
  state={
    alphabetical_words : ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    selected_filter:'',
    page:1,
    section:'costumers'
  }
  componentDidMount(){
    this.props.getCostumerData(this.props.lang_id)
    this.props.getCostumerReviews(this.props.lang_id)
    this.props.getPartnerData(this.props.lang_id)
    window.scrollTo(0,0)
  }
  onFilterBtn=(data)=>{
    this.setState({selected_filter:data});
    this.props.getCostumerData(this.props.lang_id, this.state.page, data)
    this.props.getCostumerReviews(this.props.lang_id, this.state.page, data)
    this.props.getPartnerData(this.props.lang_id, this.state.page, data)
  }
  onClickPage=(page)=>{
    this.setState({page:page+1});
    this.props.getCostumerData(this.props.lang_id, page+1, this.state.selected_filter)
    this.props.getCostumerReviews(this.props.lang_id, page+1, this.state.selected_filter)
    this.props.getPartnerData(this.props.lang_id, page+1, this.state.selected_filter)
  }
  render() {
    const {alphabetical_words, selected_filter, page, section} = this.state;
    const {costumerData, costumerReviewsData, partnerData, lang} = this.props;
    return (
      <div className=' pt-[0.1px]'>
        <Nav />
        <div className='bg-[var(--white2)] pb-[100px]'>
          <div className='container rsp-header pt-[52px] '>
            <BigImg title={lang?.customer_info} img={handBig}/>
            <div className='flex flex-wrap items-center  pt-[52px] gap-[16px] max-[320px]:justify-center'>
              <button onClick={()=>this.setState({section:"costumers", page:1})} className={`text-[16px] font-[500] border-[1px] ${section === "costumers"? "text-[var(--white)] bg-[var(--blue)]":""} border-[var(--blue)]  hover:text-[var(--white)] p-[16px] rounded-[8px] hover:bg-[var(--blue)]`}>
                  {lang?.customer}
              </button>
              <button onClick={()=>this.setState({section:"commets",  page:1})} className={`text-[16px] font-[500] border-[1px] ${section === "commets"? "text-[var(--white)] bg-[var(--blue)]":""} border-[var(--blue)] hover:text-[var(--white)] p-[16px] rounded-[8px] hover:bg-[var(--blue)]`}>
                  {lang?.reviews}
              </button>
              <button onClick={()=>this.setState({section:"parners",  page:1})} className={`text-[16px] font-[500] border-[1px] ${section === "parners"? "text-[var(--white)] bg-[var(--blue)]":""} border-[var(--blue)] hover:text-[var(--white)] p-[16px] rounded-[8px] hover:bg-[var(--blue)]`}>
                  {lang?.partners}
              </button>
            </div>
          </div>
          <div className='container pt-[52px] flex flex-col gap-[32px] '>
            <div className='flex justify-between gap-[44px]'>

              <div className='flex items-center gap-[25px] overflow-x-auto'>
                {
                  alphabetical_words?.map((data, i)=>{
                    return(
                      <span onClick={this.onFilterBtn.bind(this, data)} className={` ${selected_filter===data?"font-black":"font-medium"} cursor-pointer text-[16px] text-[var(--black)]`} key={i}>{data}</span>
                    )
                  })
                }
              </div>
              {
                selected_filter !==""?
                <button onClick={this.onFilterBtn.bind(this, "")} className='flex gap-[10px] items-center'>
                  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.7806 14.2194C14.8502 14.2891 14.9055 14.3718 14.9432 14.4628C14.9809 14.5539 15.0003 14.6515 15.0003 14.75C15.0003 14.8485 14.9809 14.9461 14.9432 15.0372C14.9055 15.1282 14.8502 15.2109 14.7806 15.2806C14.7109 15.3503 14.6281 15.4056 14.5371 15.4433C14.4461 15.481 14.3485 15.5004 14.2499 15.5004C14.1514 15.5004 14.0538 15.481 13.9628 15.4433C13.8717 15.4056 13.789 15.3503 13.7193 15.2806L7.49993 9.06031L1.28055 15.2806C1.13982 15.4214 0.948952 15.5004 0.749929 15.5004C0.550906 15.5004 0.360034 15.4214 0.219304 15.2806C0.0785732 15.1399 -0.000488277 14.949 -0.000488281 14.75C-0.000488285 14.551 0.0785732 14.3601 0.219304 14.2194L6.43962 8L0.219304 1.78062C0.0785732 1.63989 -0.000488281 1.44902 -0.000488281 1.25C-0.000488281 1.05097 0.0785732 0.860103 0.219304 0.719372C0.360034 0.578642 0.550906 0.49958 0.749929 0.49958C0.948952 0.49958 1.13982 0.578642 1.28055 0.719372L7.49993 6.93968L13.7193 0.719372C13.86 0.578642 14.0509 0.49958 14.2499 0.49958C14.449 0.49958 14.6398 0.578642 14.7806 0.719372C14.9213 0.860103 15.0003 1.05097 15.0003 1.25C15.0003 1.44902 14.9213 1.63989 14.7806 1.78062L8.56024 8L14.7806 14.2194Z" fill="black"/>
                  </svg>
                  <span className='font-[500] text-[16px] text-[var(--black)]'>
                   {lang?.reset_filter}
                  </span>
                </button>:null
              }
              
            </div>
            {
              section === "costumers"?
              <>
              <div className='grid-full-168 justify-start gap-[32px]  items-start max-[400px]:justify-center '>
              {
                costumerData?.map((data, i)=>{
                  return(
                    <Card5 key={i} image={data?.image_full_url} title={data?.translate?.title} sub_title={data?.translate?.description} />
                  )
                })
              }
            </div>
              {
                costumerData?.length >=24?
                <div>
                  <YellowBtn onClick={this.onClickPage.bind(this, page)} text={lang?.show_more} />
                </div>:null
              }
            
            </>:
             section === "commets"?
             <>
              <div className='flex flex-wrap justify-start gap-[32px]  max-[400px]:justify-center '>
              {
                costumerReviewsData?.map((data, i)=>{
                  return(
                    <Card10 key={i} data={data} />
                  )
                })
              }
            </div>
              {
                costumerReviewsData?.length >=4?
                <div>
                  <YellowBtn onClick={this.onClickPage.bind(this, page)} text={lang?.show_more} />
                </div>:null
              }
             </>:
             section === "parners"?
             <>
               <div className='flex flex-wrap justify-start gap-[32px] items-start  max-[400px]:justify-center '>
              {
                partnerData?.map((data, i)=>{
                  return(
                    <Card5 key={i} title={data?.translate?.title} image={data?.image_full_url}  sub_title={data?.translate?.description} />
                  )
                })
              }
            </div>
              {
                costumerReviewsData?.length >=4?
                <div>
                  <YellowBtn onClick={this.onClickPage.bind(this, page)} text={lang?.show_more} />
                </div>:null
              }
             </>:null
            }
            </div>
        </div>
        <div className='container pt-[60px] pb-[60px]'>
            <BigImg onClick={()=>this.props.navigate('/projects')} title={lang?.project_info} btn img={handBig}/>
        </div>
        <div  className='bg-[var(--white2)] pt-[60px]'>
            <Footer />
        </div>      
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  costumerData: state.Data.costumerData,
  lang_id: state.Data.lang_id,
  costumerReviewsData: state.Data.costumerReviewsData,
  partnerData: state.Data.partnerData,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getCostumerData, getCostumerReviews, getPartnerData}
export default connect(mapStateToProps, mapDispatchToProps)(widthHooks(Customer))