import React, { Component } from 'react'
import Nav from '../about_us_component/Nav'
import { connect } from 'react-redux';
import { addReadCount, getSingleData } from '../actions/MainAction';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Card6 from '../main_components/cards/Card6';
import hang_img from '../images/hands_mini.png'
import pen_img from '../images/pen_mini.png'
import Card3 from '../main_components/cards/Card3';
import YellowBtn from '../about_us_component/YellowBtn';
import SectionHeader from '../main_components/SectionHeader';
import Footer from '../about_us_component/Footer';
import Slider from './Slider';
function widthHooks(Component){
  return props => <Component {...props} params={useParams()} navigate={useNavigate()} />
}
export class SingleBlog extends Component {
  state={
    singleData:''
  }
  componentDidMount(){
    this.props.getSingleData(`blog/${this.props.params.id}`, this.props.lang_id, "blog")
    .then(resp=>{this.setState({singleData:resp})})
    window.scrollTo(0,0)

    if(localStorage.getItem("vssBlogRead")){
      const arr = JSON.parse(localStorage.getItem("vssBlogRead"));
      const check = arr.includes(this.props.params.id);
      if(!check){
         this.props.addReadCount({id:this.props.params.id});
        arr.push(this.props.params.id);
        localStorage.setItem("vssBlogRead", JSON.stringify(arr))
      }
    }else{
       this.props.addReadCount({id:this.props.params.id})
      let arr = [this.props.params.id];
      localStorage.setItem("vssBlogRead", JSON.stringify(arr))
    }


  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps.params.id !== this.props.params.id){
      this.props.getSingleData(`blog/${this.props.params.id}`, this.props.lang_id, "blog")
      .then(resp=>{this.setState({singleData:resp})})
      window.scrollTo(0,0)

      if(localStorage.getItem("vssBlogRead")){
        const arr = JSON.parse(localStorage.getItem("vssBlogRead"));
        const check = arr.includes(this.props.params.id);
        if(!check){
           this.props.addReadCount({id:this.props.params.id});
          arr.push(this.props.params.id);
          localStorage.setItem("vssBlogRead", JSON.stringify(arr))
        }
      }else{
         this.props.addReadCount({id:this.props.params.id})
        let arr = [this.props.params.id];
        localStorage.setItem("vssBlogRead", JSON.stringify(arr))
      }
    }
  }
  render() {
    const {singleData} = this.state;
    const {lang} = this.props;
    // console.log(singleData)
    return (
      <div className='bg-[var(--white2)] pt-[0.1px]'>
        <Nav />
        <div className='container rsp-header pt-[52px]'>
            <div className='flex items-center gap-[16px]'>
                
               
                <div>
                    <h2 className='font-[700] text-[24px] text-[var(--red)]'> 
                        {singleData?.translate?.title}
                    </h2>

                    <div className='flex gap-[16px] items-center pt-[8px]'>
                        <div className='flex gap-[12px]'>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.2075 3.5856L9.41438 0.793096C9.32152 0.700213 9.21127 0.626533 9.08993 0.576264C8.96859 0.525995 8.83853 0.500122 8.70719 0.500122C8.57585 0.500122 8.4458 0.525995 8.32446 0.576264C8.20312 0.626533 8.09287 0.700213 8 0.793096L0.29313 8.49997C0.199867 8.59249 0.125926 8.70263 0.0756045 8.82398C0.025283 8.94533 -0.000414649 9.07548 5.05934e-06 9.20685V12C5.05934e-06 12.2652 0.105362 12.5195 0.292898 12.7071C0.480435 12.8946 0.734789 13 1.00001 13H3.79313C3.9245 13.0004 4.05464 12.9747 4.17599 12.9244C4.29735 12.8741 4.40748 12.8001 4.50001 12.7068L9.72938 7.4781L9.94688 8.34685L7.64688 10.6462C7.55306 10.74 7.50032 10.8671 7.50026 10.9998C7.5002 11.1324 7.55283 11.2596 7.64657 11.3534C7.74031 11.4472 7.86747 11.5 8.0001 11.5C8.13272 11.5001 8.25993 11.4475 8.35375 11.3537L10.8538 8.85372C10.9149 8.79269 10.9592 8.71685 10.9823 8.6336C11.0054 8.55036 11.0066 8.46254 10.9856 8.37872L10.5544 6.6531L12.2075 4.99997C12.3004 4.90711 12.3741 4.79686 12.4243 4.67552C12.4746 4.55418 12.5005 4.42412 12.5005 4.29278C12.5005 4.16144 12.4746 4.03139 12.4243 3.91005C12.3741 3.78871 12.3004 3.67846 12.2075 3.5856ZM1.00001 10.2068L2.79313 12H1.00001V10.2068ZM4.00001 11.7931L1.20688 8.99997L6.50001 3.70685L9.29313 6.49997L4.00001 11.7931ZM10 5.7931L7.20751 2.99997L8.70751 1.49997L11.5 4.2931L10 5.7931Z" fill="#708088"/>
                            </svg>
                            <span className='text-[var(--gray)] text-[12px] font-[400]'> {moment(singleData?.created_at).format("DD.MM.YYYY")} </span>
                        </div>
                        <div className='flex  gap-[12px]'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.4569 7.7975C15.435 7.74813 14.9056 6.57375 13.7287 5.39687C12.1606 3.82875 10.18 3 7.99999 3C5.81999 3 3.83937 3.82875 2.27124 5.39687C1.09437 6.57375 0.562494 7.75 0.543119 7.7975C0.51469 7.86144 0.5 7.93064 0.5 8.00062C0.5 8.0706 0.51469 8.1398 0.543119 8.20375C0.564994 8.25312 1.09437 9.42688 2.27124 10.6038C3.83937 12.1713 5.81999 13 7.99999 13C10.18 13 12.1606 12.1713 13.7287 10.6038C14.9056 9.42688 15.435 8.25312 15.4569 8.20375C15.4853 8.1398 15.5 8.0706 15.5 8.00062C15.5 7.93064 15.4853 7.86144 15.4569 7.7975ZM7.99999 12C6.07624 12 4.39562 11.3006 3.00437 9.92188C2.43352 9.35418 1.94786 8.70685 1.56249 8C1.94776 7.29309 2.43343 6.64574 3.00437 6.07812C4.39562 4.69938 6.07624 4 7.99999 4C9.92374 4 11.6044 4.69938 12.9956 6.07812C13.5676 6.6456 14.0543 7.29295 14.4406 8C13.99 8.84125 12.0269 12 7.99999 12ZM7.99999 5C7.40665 5 6.82663 5.17595 6.33328 5.50559C5.83994 5.83524 5.45542 6.30377 5.22836 6.85195C5.00129 7.40013 4.94188 8.00333 5.05764 8.58527C5.17339 9.16721 5.45912 9.70176 5.87867 10.1213C6.29823 10.5409 6.83278 10.8266 7.41472 10.9424C7.99667 11.0581 8.59987 10.9987 9.14804 10.7716C9.69622 10.5446 10.1648 10.1601 10.4944 9.66671C10.824 9.17336 11 8.59334 11 8C10.9992 7.2046 10.6828 6.44202 10.1204 5.87959C9.55797 5.31716 8.79539 5.00083 7.99999 5ZM7.99999 10C7.60443 10 7.21775 9.8827 6.88885 9.66294C6.55996 9.44318 6.30361 9.13082 6.15224 8.76537C6.00086 8.39991 5.96125 7.99778 6.03842 7.60982C6.11559 7.22186 6.30608 6.86549 6.58578 6.58579C6.86549 6.30608 7.22185 6.1156 7.60981 6.03843C7.99778 5.96126 8.39991 6.00087 8.76536 6.15224C9.13081 6.30362 9.44317 6.55996 9.66293 6.88886C9.8827 7.21776 9.99999 7.60444 9.99999 8C9.99999 8.53043 9.78928 9.03914 9.41421 9.41421C9.03913 9.78929 8.53043 10 7.99999 10Z" fill="#708088"/>
                            </svg>
                            <span className='text-[var(--gray)] text-[12px] font-[400]'>
                            {singleData?.read_count}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            

        </div>
       
        <Slider gallery={singleData?.gallery} />
        <div className='container mt-[16px]'>
            <div className='p-[16px]'>
                <p className='font-[400] text-[16px] leading-[24px] text-[var(--black)]' dangerouslySetInnerHTML={{ __html: singleData?.translate?.description }}></p>
            </div>
        </div>
        <section className='bg-[var(--white)] p-[52px_0px]'>
            <div className='container flex justify-center gap-[32px] mt-[112px] max-[850px]:flex-col max-[850px]:items-center'>
              <Card6 onClick={()=>this.props.navigate("/projects")} img={pen_img} title={lang?.executed_projects} />
              <Card6 onClick={()=>this.props.navigate("/costumers")} img={hang_img} title={lang?.cooperation_relation} />
            </div>
          </section>
          {
            singleData?.other_blogs?.length !=0?
            <section className='pt-[40px] pb-[30px]'>
            <SectionHeader title={lang?.other_articles} />
            <div className='container'>
              <div className='grid grid-cols-3 mt-[52px]  max-[960px]:grid-cols-2 max-[670px]:grid-cols-1  max-[670px]:gap-[16px] gap-[64px] pt-[32px]'>
                {
                    singleData?.other_blogs?.map((data, i)=>{
                    if(i<3){
                        return(
                        <Card3 key={i} data={data} />
                        )
                    }
                    })
                }
                </div>
                {
                    singleData?.other_blogs?.length >=3?
                    <div>
                        <YellowBtn onClick={()=>this.props.navigate("/media")} text={lang?.btn_learn_more} />
                    </div>:null
                }
            </div>
          </section>:null
          }
         
          <Footer />
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  lang_id: state.Data.lang_id,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getSingleData, addReadCount}
export default connect(mapStateToProps, mapDispatchToProps)(widthHooks(SingleBlog))
