function getLang(){
    if(localStorage.getItem('lang_id')){
        return localStorage.getItem('lang_id')
    }else{
        return 1
    }
}
function getLangPath(){
    if(localStorage.getItem('langPath')){
        if(localStorage.getItem('lang_id')){
            if(localStorage.getItem('lang_id') ==1){
                return ''
            }else{
                return `${localStorage.getItem('langPath')}/`
            }
        }else{
            return ''
        }
    }else{
        return ''
    }
}

export const initialState = {
    pathLang:getLangPath(),
    loader:false,
    lang_id:getLang(),
    whyUsData:[],
    servicesData:[],
    blogsData:[],
    mainCounterData:[],
    projectsData:[],
    partnerData:[],
    founderData:'',
    companyAbout:'',
    counterAbout:[],
    costumerData:[],
    costumerReviewsData:[],
    newsData:[],
    videosData:[],
    aboutData:'',
    mainSliderData:[],
    lang:''
}
