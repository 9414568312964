import React, { Component } from 'react'

export class SectionHeader extends Component {
  render() {
    const {title, image=""} = this.props;
    // console.log(image)
    return (
      <div className=' flex items-center'>
        <span className='border absolute w-full border-dashed border-[var(--blue)] bg-red-100 w-[100%]'></span>
        <div className='container flex'>
            <h2 className='text-[var(--red)] max-[600px]:text-[20px] flex items-center gap-[6px]  brownImg relative z-2 bg-[var(--white2)] pl-[16px] pr-[16px] leading-[36px] font-[700] text-[28px] '>
                {image !=""?<img src={image} className='w-[36px] h-[36px]' />:null}
                {title}
            </h2>
        </div>
        {/* <span className='border border-dashed border-[var(--blue)] bg-red-100 w-[100%]'></span> */}
      </div>
    )
  }
}

export default SectionHeader