import React, { Component } from 'react'
import img from '../../images/img.png'
import { ReactSVG } from "react-svg";
export class Card1 extends Component {
  render() {
    const {data} = this.props;
    return (
        <div className='flex flex-col gap-[24px] '>
          <img src={data?.image_full_url} className='w-[368px] h-[144px] rounded-[8px] ' />
          <div className='pl-[16px] flex flex-col gap-[24px]'>
            <div className='flex gap-[32px] items-center  max-[400px]:gap-[16px] max-[400px]:'>
              <img src={data?.svg_full_url} className='w-[40px] h-[42px]' style={{ filter: `red` }} />
              {/* <object data={data?.svg_full_url} width="40" color="red" height="42"> </object> */}
              {/* <svg xmlnsXlink={data?.svg_full_url}> </svg> */}
              <h3 className='text-[var(--blue)] max-w-[190px] text-[20px] leading-[28px] font-[700] max-[400px]:leading-[20px]'>
                {data?.translate?.title}
              </h3>
            </div>
            <div className='flex flex-col gap-[20px]'>
              <p dangerouslySetInnerHTML={{ __html: data?.translate?.description}}></p>
            </div>
          </div>
        </div>
    )
  }
}

export default Card1