import React, { Component } from 'react'
import Nav from '../about_us_component/Nav'
import prf from '../images/profil.png'
import group from '../images/group.png'
import YellowBtn from '../about_us_component/YellowBtn'
import Footer from '../about_us_component/Footer'
import hang_img from '../images/hands_mini.png'
import pen_img from '../images/pen_mini.png'
import Card6 from '../main_components/cards/Card6'
import { connect } from 'react-redux'
import { getFounderData, getCompanyAbout, getCounterAbout } from '../actions/MainAction';
import Card4 from '../main_components/cards/Card4'
import { widthHooks } from '../actions/withHooks'
export class History extends Component {
  componentDidMount(){
    this.props.getFounderData(this.props.lang_id);
    this.props.getCompanyAbout(this.props.lang_id);
    this.props.getCounterAbout(this.props.lang_id);
    window.scrollTo(0,0)
  }
  render() {
    const {founderData, companyAbout, counterAbout, lang} = this.props;
    // console.log(counterAbout)
    return (
      <div className='bg-[var(--white2)] pt-[0.1px]'>
        <header>
              <Nav />
              <div className='container flex flex-col   rsp-header items-center pt-[52px] rounded-[8px]   '>
                  <div className='bg-img min-h-[522px]  p-[32px_0px] w-full flex justify-center gap-[132px] max-[1000px]:flex-col max-[1000px]:gap-[32px]'  >
                    <div className='flex flex-col items-center gap-[32px] max-[1000px]:gap-[16px]'>
                      <img src={founderData?.image_full_url} className='max-w-[132px]  max-h-[132px] w-[132px]  h-[132px] rounded-full' />
                      <div className='flex flex-col items-start text-white'>
                        <p className='text-start'  dangerouslySetInnerHTML={{ __html: founderData?.translate?.description }}></p>
                        {/* <span className='font-[700] text-[16px] text-[var(--white)]'>
                          HEKAYƏMİZ
                        </span>
                        <span className='font-[900] text-[28px] text-[var(--white)]'>
                          15 İL ÖNCƏ
                        </span>
                        <span className='font-[700] text-[16px] text-[var(--white)]'>
                        BAŞLADI
                        </span> */}
                      </div>
                      <div>
                        <span className='font-[700] text-[16px] text-[var(--white)]'>
                        {founderData?.translate?.title}
                        </span>
                      </div>
                    </div>
                    <div className='flex flex-col gap-[32px]   w-[566px] max-[1000px]-mb-[20px] max-[1000px]:w-[100%]  '>
                      <p className='font-[400] text-[16px] text-center text-[var(--white)]  max-[1000px]:text-black  max-[1000px]:p-[10px] max' dangerouslySetInnerHTML={{ __html: founderData?.translate?.text }}></p>
                    </div>
                  </div>

                  <div className=' flex flex-col gap-[16px] max-w-[968px] w-full bg-[var(--white)]  mt-[-132px] rounded-[8px] p-[32px_16px] max-[1000px]:m-[0px_16px]'>
                    <h3 className='font-[700] text-[16px] text-[var(--red)]'>
                    {companyAbout?.translate?.title}
                    </h3>
                    <p className='font-[400] text-[16px] w-full text-[var(--black)]' dangerouslySetInnerHTML={{ __html: companyAbout?.translate?.description }}></p>
                  </div>
              </div>
          </header>
          <section className=' container w-full  p-[32px_0px]'>
            <img src={companyAbout?.image_full_url} className='w-full h-[600px] rounded-[8px] max-[950px]:h-auto' />
            
            <div className='  flex gap-[32px] justify-center pt-[52px] items-center max-[900px]:flex-col '>
              <div className='bg-[var(--blue)] h-[264px] max-w-[468px] p-[32px_16px] rounded-[8px] text-center'>
                <h4 className='font-[700] text-[16px] text-[var(--white)]'>
                  {lang?.mission}
                </h4>

                <p className='font-[400] text-[16px] text-[var(--white)] pt-[32px]'>
                 {lang?.mission_text}
                </p>
              </div>
              <div className='bg-[var(--blue)] max-w-[468px] h-[264px] p-[32px_16px] rounded-[8px] text-center'>
                <h4 className='font-[700] text-[16px] text-[var(--white)]'>
                {lang?.vision}
                </h4>

                <p className='font-[400] text-[16px] text-[var(--white)] pt-[32px]'>
                {lang?.vision_text}
                </p>
              </div>
            </div>

            <div className='container grid grid-cols-4 gap-[32px] pt-[52px] max-[1000px]:grid-cols-2 max-[600px]:grid-cols-1'>
            {
              counterAbout?.map((data, i)=>{
                return(
                  <Card4 title={data?.translate?.title} count={data?.count} key={i} bg="bg-white" />
                )
              })
            }
            </div>
          </section>
          <section className='bg-[var(--white)] p-[52px_0px]'>
            <div className='container flex justify-center gap-[32px] mt-[112px] max-[850px]:flex-col max-[850px]:items-center'>
              <Card6 onClick={()=>this.props.navigate("/projects")} img={pen_img} title={lang?.executed_projects} />
              <Card6 onClick={()=>this.props.navigate("/costumers")} img={hang_img} title={lang?.cooperation_relation} />
            </div>
          </section>

          <Footer />
          
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang_id: state.Data.lang_id,
  founderData: state.Data.founderData,
  companyAbout: state.Data.companyAbout,
  counterAbout: state.Data.counterAbout,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getFounderData, getCompanyAbout, getCounterAbout}
export default connect(mapStateToProps, mapDispatchToProps)(widthHooks(History))