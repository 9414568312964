import React, { Component } from 'react'
import YellowBtn from '../about_us_component/YellowBtn';
import { connect } from 'react-redux';

export class BigImg extends Component {
  render() {
    const {img, title, btn=false, lang, onClick=()=>{}} = this.props;
    return (
        <div className='w-full rounded-[8px] relative bg-red-100 h-[320px] '>
            <img src={img} className='w-full h-full rounded-[8px]' />
            <div className='absolute rounded-[8px] top-0 left-0 w-full flex items-end h-full justify-between p-[60px_100px_60px_100px] bg-[rgba(0,135,202,0.32)] max-[840px]:flex-col max-[840px]:items-center max-[500px]:p-[12px_20px_12px_20px] max-[500px]:justify-center max-[500px]:gap-[20px]'>
                <h2 className='text-white font-black text-[48px] max-w-[403px] max-[840px]:max-w-full max-[840px]:text-center max-[840px]:text-[32px] max-[630px]:text-[24px] max-[440px]:mb-[-40px]'>{title}</h2>
                {
                    btn?
                    <YellowBtn onClick={()=>onClick()} text={lang?.btn_learn_more} />:null
                }
                
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(BigImg)
