import React, { Component } from 'react'
import img from "../images/loading (1).png"
export class LoaderPage extends Component {
  render() {
    return (
      <div className='fixed z-[999] bg-[rgba(0,0,0,0.5)] top-0 left-0 w-full h-screen flex justify-center items-center'>
        <img src={img} />
      </div>
    )
  }
}

export default LoaderPage
