import React, { Component } from 'react'
import YellowBtn from '../../about_us_component/YellowBtn'
import Card2 from '../cards/Card2'
import { getServices } from '../../actions/MainAction'
import { connect } from 'react-redux';
import { widthHooks } from '../../actions/withHooks';
export class DirectionSection extends Component {
  componentDidMount(){
    this.props.getServices(this.props.lang_id);
  }
  render() {
    const {servicesData, lang} = this.props;
    return (
        <section className='bg-[var(--blue)] mt-[52px]'>
          <div className='container flex flex-col gap-[52px] p-[52px_0_52px_0] '>
            <h2 className='  leading-[36px] text-[var(--white)] font-[700] text-[28px] '>
             {lang?.directions}
            </h2>
            <div className='flex flex-wrap gap-[16px]' >
              {
                servicesData?.map((data, i)=>{
                  return(
                    <Card2 index={i} key={i} data={data} />
                  )
                })
              }
            </div>
            <div>
                <YellowBtn onClick={()=>this.props.navigate("/service")} text={lang?.btn_see_all} />
            </div>
          </div>
        </section>
    )
  }
}
const mapStateToProps = (state)=>({
  servicesData: state.Data.servicesData,
  lang_id: state.Data.lang_id,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getServices}
export default connect(mapStateToProps, mapDispatchToProps)(widthHooks(DirectionSection))