import React, { Component } from 'react'
import Nav from '../about_us_component/Nav'
import { connect } from 'react-redux';
import { getNews, getBlogs, getVideos } from '../actions/MainAction';
import Card3 from '../main_components/cards/Card3';
import Card11 from '../main_components/cards/Card11';
import BigImg from '../main_components/BigImg';
import handBig from "../images/hands_big.png"
import Footer from '../about_us_component/Footer';
import SectionHeader from '../main_components/SectionHeader';
import Projects from '../main_components/sections/Projects';
import YellowBtn from '../about_us_component/YellowBtn';
import { widthHooks } from '../actions/withHooks';

export class MediaPage extends Component {
  state={
    section:"news",
    page:1
  }
  componentDidMount(){
    this.props.getNews(this.props.lang_id);
    this.props.getBlogs(this.props.lang_id);
    this.props.getVideos(this.props.lang_id);
    window.scrollTo(0,0)
  }
  onLoadData=(page)=>{
    this.setState({page:page+1})
    if(this.state.section === "news"){
      this.props.getNews(this.props.lang_id, page+1);
    }else{
      this.props.getBlogs(this.props.lang_id, page+1);
    }
  }
  render() {
    const {section, page} = this.state;
    const {newsData, blogsData, videosData, lang} = this.props;
    // console.log(videosData)
    return (
      <div className=' pt-[0.1px]'>
      <Nav />
      <div className='bg-[var(--white2)] pb-[100px]'>
        <div className='container rsp-header pt-[52px] '>
           <div className='flex flex-wrap items-center  pt-[52px] gap-[16px] max-[320px]:justify-center'>
              <button onClick={()=>this.setState({section:"news", page:1})} className={`text-[16px] font-[500] border-[1px] ${section === "news"? "text-[var(--white)] bg-[var(--blue)]":""} border-[var(--blue)]  hover:text-[var(--white)] p-[16px] rounded-[8px] hover:bg-[var(--blue)]`}>
              {lang?.news}
              </button>
              <button onClick={()=>this.setState({section:"blog", page:1})} className={`text-[16px] font-[500] border-[1px] ${section === "blog"? "text-[var(--white)] bg-[var(--blue)]":""} border-[var(--blue)] hover:text-[var(--white)] p-[16px] rounded-[8px] hover:bg-[var(--blue)]`}>
              {lang?.blog}
              </button>
              <button onClick={()=>this.setState({section:"addvertising", page:1})} className={`text-[16px] font-[500] border-[1px] ${section === "addvertising"? "text-[var(--white)] bg-[var(--blue)]":""} border-[var(--blue)] hover:text-[var(--white)] p-[16px] rounded-[8px] hover:bg-[var(--blue)]`}>
               {lang?.commercial}
              </button>
            </div>
            {
              section === "news"?
              <div className='grid grid-cols-3 mt-[52px]  max-[960px]:grid-cols-2 max-[670px]:grid-cols-1  max-[670px]:gap-[16px] gap-[64px] pt-[32px]'>
              {
                newsData?.map((data, i)=>{
                  return(
                    <Card3 key={i} data={data} />
                  )
                })
              }
            </div>:
              section === "blog"?
              <div className='grid grid-cols-3 mt-[52px]  max-[960px]:grid-cols-2 max-[670px]:grid-cols-1  max-[670px]:gap-[16px] gap-[64px] pt-[32px]'>
              {
                blogsData?.map((data, i)=>{
                  return(
                    <Card3 key={i} data={data} />
                  )
                })
              }
            </div>:
              section === "addvertising"?
              <div className='grid grid-cols-3 mt-[52px]  max-[960px]:grid-cols-2 max-[670px]:grid-cols-1  max-[670px]:gap-[16px] gap-[64px] pt-[32px]'>
              {
                videosData?.map((data, i)=>{
                  return(
                    <Card11 key={i} video={data?.video_full_url} />
                  )
                })
              }
            </div>:null
            }
             {
               (section === "news" && newsData?.length >=6) || (section === "blog" && blogsData?.length >=6) ?
                <div>
                    <YellowBtn onClick={this.onLoadData.bind(this, page)} text={lang?.show_more} />
                </div>:null
            }
        </div>
      </div>
      <div className='container pt-[60px] pb-[60px]'>
            <BigImg  onClick={()=>this.props.navigate('/costumers')} title={lang?.customer_info} btn img={handBig}/>
        </div>
        <section className=' mt-[52px] bg-[var(--white2)] pt-[60px]'>
            <SectionHeader title={lang?.project} />
            <div className='container'>
                <Projects />
                <YellowBtn onClick={()=>this.props.navigate('/projects')} text={lang?.btn_see_all} />
            </div>
        </section>
        <div  className='bg-[var(--white2)] pt-[60px]'>
            <Footer />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  newsData: state.Data.newsData,
  blogsData: state.Data.blogsData,
  videosData: state.Data.videosData,
  lang: state.Data.lang,
  lang_id: state.Data.lang_id,
});
const mapDispatchToProps = {getNews, getBlogs, getVideos}
export default connect(mapStateToProps, mapDispatchToProps)(widthHooks(MediaPage))