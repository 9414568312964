import React, { Component } from 'react'
import boy from '../../images/boy-img.png'
import YellowBtn from '../../about_us_component/YellowBtn'
import { connect } from 'react-redux';

export class Card6 extends Component {
  render() {
    const {img, title, lang, onClick=()=>{}} = this.props;
    return (
        <div className='relative  max-w-[569px] w-[569px] max-[620px]:w-full h-[320px] rounded-[8px]'> 
            <img  src={img} className='w-full h-full rounded-[8px]'  />
            <div className='absolute gap-[16px] flex bg-[rgba(0,135,202,0.32)] flex-col items-center justify-center w-full h-full top-0 left-0 rounded-[8px]'>
                <h3 className='text-[white] max-w-[403px] max-h-[112px] leading-[56px] text-center text-[48px] font-[900] max-[450px]:leading-[38px] max-[450px]:text-[32px]'>{title}</h3>
                <YellowBtn onClick={()=>onClick()} text={lang?.btn_learn_more} />
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(Card6)