import React, { Component } from 'react'
import AboutUs from './about_us_component/AboutUs'
import History from './history_component/History'
import Service from './service_component/Service'
import Customer from './customer_component/Customer'
import Map from './contact_component/Map'
import Project from './project_component/Project'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MediaPage from './media_component/MediaPage'
import SingleBlog from './single_data/SingleBlog'
import SingleProject from './single_data/SingleProject'
import SingleNews from './single_data/SingleNews'
import { connect } from 'react-redux'
import { getTranslate, getAbout } from './actions/MainAction'
import LoaderPage from './main_components/LoaderPage'
export class App extends Component {
  componentDidMount(){
    this.props.getTranslate(this.props.lang_id)
    this.props.getAbout();
  }
  render() {
    const {loader} = this.props;
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<AboutUs />} />
          <Route path='/history' element={<History />} />
          <Route path='/service' element={<Service />} />
          <Route path='/projects' element={<Project />} />
          <Route path='/costumers' element={<Customer />} />
          <Route path='/media' element={<MediaPage />} />
          <Route path='/about' element={<Map />} />
          <Route path='/blog/:id' element={<SingleBlog />} />
          <Route path='/project/:id' element={<SingleProject />} />
          <Route path='/news/:id' element={<SingleNews />} />
        </Routes>
        {loader?<LoaderPage /> :null}
        
        {/*  */}
        {/* <Service /> */}
        {/* <Customer /> */}
        {/* <Project /> */}
        {/* <Map /> */}
      </BrowserRouter>
    )
  }
}
const mapStateToProps = (state)=>({
  lang_id: state.Data.lang_id,
  loader: state.Data.loader,
});
const mapDispatchToProps = {getTranslate, getAbout}
export default connect(mapStateToProps, mapDispatchToProps)(App)