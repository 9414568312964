import React, { Component } from 'react'
import Nav from '../about_us_component/Nav'
import penBig from "../images/pen_big.png"
import handBig from "../images/hands_big.png"
import BigImg from '../main_components/BigImg'
import { connect } from 'react-redux'
import { getProjects } from '../actions/MainAction'
import Card3 from '../main_components/cards/Card3'
import YellowBtn from '../about_us_component/YellowBtn'
import Footer from '../about_us_component/Footer'
import { widthHooks } from '../actions/withHooks'
export class Project extends Component {
    state={
        page:1
    }
    componentDidMount(){
        this.props.getProjects(this.props.lang_id);
        window.scrollTo(0,0)
    }

    onLoadData=(page)=>{
        this.setState({page:page+1})
        this.props.getProjects(this.props.lang_id, page+1);
    }
  render() {
    const {projectsData, lang} = this.props;
    const {page} = this.state;
    // console.log(projectsData)
    return (
      
    <div className=' pt-[0.1px]'>
        <Nav  />
        <div className='bg-[var(--white2)]'>
            <div className='container rsp-header pt-[52px] pb-[50px]'>
                <BigImg title={lang?.project_info} img={penBig}/>
                <div className='grid grid-cols-3 mt-[52px]  max-[960px]:grid-cols-2 max-[670px]:grid-cols-1  max-[670px]:gap-[16px] gap-[64px] pt-[32px]'>
                {
                    projectsData?.map((data, i)=>{
                        return(
                            <Card3 key={i} data={data} />
                        )
                    })
                }
                </div>
                {
                    projectsData?.length >=6?
                    <div>
                        <YellowBtn onClick={this.onLoadData.bind(this,page)} text={lang?.show_more} />
                    </div>:null
                }
                <div></div>
            </div>
        </div>
        
        <div className='container pt-[60px] pb-[60px]'>
            <BigImg onClick={()=>this.props.navigate('/costumers')} title={lang?.customer_info} btn img={handBig}/>
        </div>
        <div  className='bg-[var(--white2)] pt-[60px]'>
            <Footer />
        </div>
    </div>
      
    )
  }
}
const mapStateToProps = (state)=>({
    projectsData: state.Data.projectsData,
    lang_id: state.Data.lang_id,
    lang: state.Data.lang,
  });
  const mapDispatchToProps = {getProjects}
  export default connect(mapStateToProps, mapDispatchToProps) (widthHooks(Project))

  