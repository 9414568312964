import React, { Component } from 'react'
import YellowBtn from '../../about_us_component/YellowBtn'
import Card3 from '../cards/Card3'
import SectionHeader from '../SectionHeader'
import { getBlogs } from '../../actions/MainAction';
import { connect } from 'react-redux';
import { widthHooks } from '../../actions/withHooks';
export class SpesiphicBlogs extends Component {
    componentDidMount(){
        this.props.getBlogs(this.props.lang_id);
    }
  render() {
    const {blogsData, lang} = this.props;
  
    return (
        <section className='mt-[52px] '>
            <SectionHeader title={lang?.blog_post} />
            <div className='container'>
                <div className='grid grid-cols-3 gap-[64px] pt-[32px]  max-[960px]:grid-cols-2 max-[670px]:grid-cols-1 '>
                    {
                        blogsData?.map((data, i)=>{
                            if(i<3){
                                return(
                                    <Card3 key={i} data={data} />
                                )
                            }
                        })
                    }
                </div>
                <div>
                    <YellowBtn onClick={()=>this.props.navigate("/media")} text={lang?.btn_see_all} />
                </div>
            </div>
        </section>
    )
  }
}
const mapStateToProps = (state)=>({
    blogsData: state.Data.blogsData,
    lang_id: state.Data.lang_id,
    lang: state.Data.lang,
  });
  const mapDispatchToProps = {getBlogs}
  export default connect(mapStateToProps, mapDispatchToProps)(widthHooks(SpesiphicBlogs))