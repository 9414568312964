import axios from "axios";
import { MAIN_API } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";

export const getMainSlider = (lang_id=1)=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/main-slider`, {
        params: {lang_id}
    }).then(resp=>{
        dispatch(changeStateValue({name:'mainSliderData', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getWhyUs = (lang_id=1)=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/why-us`, {
        params: {lang_id}
    }).then(resp=>{
        dispatch(changeStateValue({name:'whyUsData', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getServices = (lang_id=1)=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/services`, {
        params: {lang_id}
    }).then(resp=>{
        dispatch(changeStateValue({name:'servicesData', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getBlogs = (lang_id=1)=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/blog`, {
        params: {lang_id, type:"blog"}
    }).then(resp=>{
        dispatch(changeStateValue({name:'blogsData', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getCounterMain = (lang_id=1)=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/counter`, {
        params: {lang_id, type:"main"}
    }).then(resp=>{
        dispatch(changeStateValue({name:'mainCounterData', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getProjects = (lang_id=1, page=1)=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/blog`, {
        params: {lang_id, type:"project", page}
    }).then(resp=>{
        dispatch(changeStateValue({name:'projectsData', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getNews = (lang_id=1)=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/blog`, {
        params: {lang_id, type:"news"}
    }).then(resp=>{
        dispatch(changeStateValue({name:'newsData', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getPartnerData = (lang_id=1,  page=1, keyword="")=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/costumer`, {
        params: {lang_id, type:"partner", page, keyword}
    }).then(resp=>{
        dispatch(changeStateValue({name:'partnerData', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getFounderData = (lang_id=1)=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/founder`, {
        params: {lang_id, type:"partner"}
    }).then(resp=>{
        dispatch(changeStateValue({name:'founderData', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getCompanyAbout = (lang_id=1)=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/company-about`, {
        params: {lang_id, type:"partner"}
    }).then(resp=>{
        dispatch(changeStateValue({name:'companyAbout', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getCounterAbout = (lang_id=1)=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/counter`, {
        params: {lang_id, type:"about"}
    }).then(resp=>{
        dispatch(changeStateValue({name:'counterAbout', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}

export const getCostumerData = (lang_id=1, page=1, keyword="")=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/costumer`, {
        params: {lang_id, type:"costumer", page, keyword}
    }).then(resp=>{
        dispatch(changeStateValue({name:'costumerData', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getCostumerReviews = (lang_id=1, page=1, keyword="")=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/costumer-reviews`, {
        params: {lang_id, type:"costumer", page, keyword}
    }).then(resp=>{
        dispatch(changeStateValue({name:'costumerReviewsData', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getVideos = (lang_id=1, page=1, keyword="")=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/videos`, {
        params: {lang_id, type:"costumer", page, keyword}
    }).then(resp=>{
        dispatch(changeStateValue({name:'videosData', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getAbout = (lang_id=1, page=1, keyword="")=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/about`, {
        params: {lang_id, type:"costumer", page, keyword}
    }).then(resp=>{
        dispatch(changeStateValue({name:'aboutData', value:resp.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
    })
}
export const getSingleData = (url,lang_id=1, type)=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/${url}`, {
        params: {lang_id, type}
    }).then(resp=>{
        dispatch(changeStateValue({name:'loader', value:false}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
        return '';
    })
}
export const getTranslate = (lang_id=1)=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/content`, {
        params: {lang_id}
    }).then(resp=>{
        dispatch(changeStateValue({name:'lang', value:resp?.data}));
        // return resp?.data;
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
        return '';
    })
}

export const addReadCount=(params)=>async dispatch => {
    return await axios.post(`${MAIN_API}/blog-read`, params)
    .then(resp=>{
        return "success";
    }).catch(err=>{
        return "error";
    })
}

export const getlng = (lang_id=1)=> async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}));
    return await axios.get(`${MAIN_API}/language`, {
        params: {lang_id}
    }).then(resp=>{
        dispatch(changeStateValue({name:'laoder', value:false}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}));
        return [];
    })
}