import React, { Component } from 'react'

export class Card5 extends Component {
  render() {
    const {image, title="", sub_title=""} = this.props;
    // console.log(image)
    return (
      <div className='rounded-[8px] min-w-[168px] flex flex-col justify-center items-center'>
        <img  src={image}  className='max-w-[168px] h-[112px] rounded-[8px]' />
        {
          title !==""?
          <h3 className='font-[500] text-[16px] text-[var(--black)] text-center pt-[8px] leading-[24px]'>{title}</h3>:null
        }
        {
          sub_title !==""?
          <p className='font-[400] max-w-[132px] text-[12px] text-[var(--gray)] text-center leading-[20px]' dangerouslySetInnerHTML={{ __html:sub_title }}></p>:null
        }
        
      </div>
    )
  }
}

export default Card5