import moment from 'moment'
import React, { Component } from 'react'

export class Card10 extends Component {
  render() {
    const {data} = this.props;
    return (
        <div className='max-w-[568px] max-[1210px]:max-w-full  bg-[var(--white)] max-[610px]:min-w-full min-w-[568px] rounded-[8px] p-[8px]  mt-[16px]'>
            <div className='p-[16px_32px] flex gap-[16px] items-center max-[400px]:p-[8px_16] '>
            {
              data?.image_full_url?
              <img src={data?.image_full_url} className='w-[64px] h-[64px] rounded-full' />:null
            }
                
                <div>
                    <h3 className='font-[500] text-[16px] leading-[24px] text-[var(--black)]'>
                        {data?.translate?.title}
                    </h3>

                    <span className='font-[400] text-[12px] text-[#708088] leading-[20px]' >{data?.translate?.sub_title}</span>
                </div>
            </div>

            <div className='p-[16px_32px]'>
                <p className='font-[500] text-[12px] text-[#708088] leading-[20px]' dangerouslySetInnerHTML={{ __html:data?.translate?.description }}></p>
            </div>

            <div className='flex  justify-between items-end'>
                <div className='p-[32px]'>
                    <span className='font-[500] block text-[12px] leading-[20px] text-[#708088]'>
                    {data?.translate?.position}:
                    </span>
                    <span className='block font-[500] text-[12px] leading-[20px] text-[var(--black)] '>
                    {data?.translate?.user_name}
                    </span>
                </div>

                <div className='flex p-[24px_32px] gap-[12px]'>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.2075 3.5856L9.41438 0.793096C9.32152 0.700213 9.21127 0.626533 9.08993 0.576264C8.96859 0.525995 8.83853 0.500122 8.70719 0.500122C8.57585 0.500122 8.4458 0.525995 8.32446 0.576264C8.20312 0.626533 8.09287 0.700213 8 0.793096L0.29313 8.49997C0.199867 8.59249 0.125926 8.70263 0.0756045 8.82398C0.025283 8.94533 -0.000414649 9.07548 5.05934e-06 9.20685V12C5.05934e-06 12.2652 0.105362 12.5195 0.292898 12.7071C0.480435 12.8946 0.734789 13 1.00001 13H3.79313C3.9245 13.0004 4.05464 12.9747 4.17599 12.9244C4.29735 12.8741 4.40748 12.8001 4.50001 12.7068L9.72938 7.4781L9.94688 8.34685L7.64688 10.6462C7.55306 10.74 7.50032 10.8671 7.50026 10.9998C7.5002 11.1324 7.55283 11.2596 7.64657 11.3534C7.74031 11.4472 7.86747 11.5 8.0001 11.5C8.13272 11.5001 8.25993 11.4475 8.35375 11.3537L10.8538 8.85372C10.9149 8.79269 10.9592 8.71685 10.9823 8.6336C11.0054 8.55036 11.0066 8.46254 10.9856 8.37872L10.5544 6.6531L12.2075 4.99997C12.3004 4.90711 12.3741 4.79686 12.4243 4.67552C12.4746 4.55418 12.5005 4.42412 12.5005 4.29278C12.5005 4.16144 12.4746 4.03139 12.4243 3.91005C12.3741 3.78871 12.3004 3.67846 12.2075 3.5856ZM1.00001 10.2068L2.79313 12H1.00001V10.2068ZM4.00001 11.7931L1.20688 8.99997L6.50001 3.70685L9.29313 6.49997L4.00001 11.7931ZM10 5.7931L7.20751 2.99997L8.70751 1.49997L11.5 4.2931L10 5.7931Z" fill="#708088"/>
                    </svg>
                    <span className='text-[var(--gray)] text-[12px] font-[400]'>{moment(data?.created_at).format("DD.MM.YYYY")} </span>
                </div>
            </div>
        </div>
    )
  }
}

export default Card10
