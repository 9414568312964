import React, { Component } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export class Slider extends Component {
    state={
        popupImg:''
    }
  render() {
    const {gallery=[]} = this.props;
    const {popupImg} = this.state
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 2100 },
          items: 6
        },
        desktop: {
          breakpoint: { max: 2100, min: 1770 },
          items: 5
        },
        tablet: {
          breakpoint: { max: 1770, min: 1400 },
          items: 4
        },
        mobile: {
          breakpoint: { max: 1400, min: 1000 },
          items: 3
        },
        mobile2: {
          breakpoint: { max: 1000, min: 800 },
          items: 2
        },
        mobile3: {
          breakpoint: { max: 800, min: 0 },
          items: 1
        }
      };
    return (
        <div className=' gap-[32px] items-center mt-[30px]'>
              <Carousel 
                swipeable={true}
                draggable={false}
                
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={2000}
                keyBoardControl={false}
                customTransition="all 2s"
                transitionDuration={2000}
                containerClass="carousel-container2"
              //   deviceType={this.props.deviceType}
                
              >
            {
            gallery?.map((data, i)=>{
                return(
                <div onClick={()=>this.setState({popupImg: data?.image_full_url})} key={i} className='min-w-[368px] max-[380px]:min-w-full h-[245px] rounded-[8px]'>
                    <img className='w-full h-[245px] rounded-[8px]' src={data?.image_full_url} />
                </div>
                )
            })
            }
            </Carousel>
            {
                popupImg !=""?
                 <div onClick={(e)=>{if(e.target.className.includes("fixed")){this.setState({popupImg:''})}}} className=' fixed top-0 left-0 w-full h-screen  z-10 flex justify-center items-center '>
                    <img className='max-h-[60vh] rounded-[10px]' src={popupImg} />
                </div>:null
            }
           
    </div>
    )
  }
}

export default Slider
