import React, { Component } from 'react'
import SectionHeader from '../SectionHeader'
import MainCounterSection from './MainCounterSection'
import YellowBtn from '../../about_us_component/YellowBtn'
import Projects from './Projects'
import { connect } from 'react-redux'
import { widthHooks } from '../../actions/withHooks'

export class ProjectsSection extends Component {
  render() {
    const {lang} = this.props
    return (
        <section className=' mt-[52px] '>
            <SectionHeader title={lang?.project} />
            <div className='container'>
                <MainCounterSection />
                <Projects />
                <YellowBtn onClick={()=>this.props.navigate("/projects")} text={lang?.btn_see_all} />
            </div>
        </section>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
});
export default connect(mapStateToProps)(widthHooks(ProjectsSection))