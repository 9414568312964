import React, { Component } from 'react'

export class YellowBtn extends Component {
  render() {
    const {text, onClick=()=>{}} = this.props
    return (
        <button onClick={()=>onClick()} className='max-[600px]:text-[12px] max-[600px]:p-[12px_28px_12px_28px] mt-[52px] bg-[var(--yellow)] p-[16px_32px_16px_32px] rounded-[8px] text-[var(--black)] font-[500] text-[16px] max-[400px]:mt-[36px] '>
        {text}
        </button>
    )
  }
}

export default YellowBtn