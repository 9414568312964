import React, { Component } from 'react'
import Nav from '../about_us_component/Nav'
import boy from '../images/boy-img.png'
import Card7 from '../main_components/cards/Card7'
import SectionHeader from '../main_components/SectionHeader'
import ServiceSection from '../main_components/sections/ServiceSection'
import Footer from '../about_us_component/Footer'
import { getServices } from '../actions/MainAction'
import { connect } from 'react-redux';
export class Service extends Component {
  componentDidMount(){
    // window.scrollTo(0,0)
    this.props.getServices(this.props.lang_id);
    setTimeout(()=>{
      if(localStorage.getItem("redirectSlide")){
        this.onScrollView(localStorage.getItem("redirectSlide"))
        localStorage.removeItem("redirectSlide")
      }
    }, 300)
    if(!localStorage.getItem("redirectSlide")){
      window.scrollTo(0,0)
    }
  }
  onScrollView=(index)=>{
    const sections = document.querySelectorAll(".service-section");
    if(sections){
      sections[index].scrollIntoView()
    }
  }
  render() {
    const {servicesData} = this.props;
    // console.log(servicesData)
    return (
      <div className='bg-[var(--white2)] pt-[0.1px]'>
        <Nav />
        <section className=' container rsp-header  pt-[52px]'>
            <div className='grid grid-cols-4 gap-[32px] justify-center items-center  max-[1000px]:grid-cols-3 max-[750px]:grid-cols-2  max-[500px]:grid-cols-1 '>
              {
                servicesData?.map((data, i)=>{
                  return(
                    <Card7 onScrollView={this.onScrollView.bind(this, i)} key={i} data={data} />
                  )
                })
              }
            </div>
            
        </section>
        {
          servicesData?.map((data, i)=>{
            return(
              <ServiceSection  key={i} data={data} /> 
            )
          })
        }
        <Footer />
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  servicesData: state.Data.servicesData,
  lang_id: state.Data.lang_id,
});
const mapDispatchToProps = {getServices}
export default connect(mapStateToProps, mapDispatchToProps) (Service)