import React, { Component } from 'react'
import boy from '../../images/boy-img.png'

export class Card9 extends Component {
  render() {
    const {image} = this.props;
    return (
        <div className=' max-w-[368px] h-[192px] rounded-[8px] bg-[#D9D9D9] flex items-center justify-center'>
            <img src={image} className='w-[336px] m-[16px] h-[160px] rounded-[4px]' />
        </div>
    )
  }
}

export default Card9