import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getlng } from '../actions/MainAction';
import { changeStateValue } from '../redux/MainReducer';
export class ChangeLang extends Component {
    state={
        visible: false,
        languages:[]
    }

    componentDidMount(){
        this.props.getlng()
        .then(resp=>{
            this.setState({languages:resp})
        })
    }
    getSelectedLang(lang_id, languages){
        const x = languages?.find(y=>y.id === +lang_id);
        if(x){
            return x?.title
        }else{
            return ''
        }
        
    }

    changeLangBtn=(data)=>{
        this.props.changeStateValue({
            name:'lang_id',
            value: data?.id
        });
        this.setState({visible:false});
        localStorage.setItem("lang_id", data?.id);
        window.location.reload()
        // console.log(data)
    }
  render() {
    const {visible, languages} = this.state;
    const {lang_id} = this.props;
    // console.log(lang_id)
    return (
      <div className='max-[1000px]:ml-[20px] relative z-10'>
       <div onClick={()=>this.setState({visible: !visible})} className='flex cursor-pointer items-center gap-[10px]'>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0.25C8.07164 0.25 6.18657 0.821828 4.58319 1.89317C2.97982 2.96451 1.73013 4.48726 0.992179 6.26884C0.254225 8.05042 0.061142 10.0108 0.437348 11.9021C0.813554 13.7934 1.74215 15.5307 3.10571 16.8943C4.46928 18.2579 6.20656 19.1865 8.09787 19.5627C9.98919 19.9389 11.9496 19.7458 13.7312 19.0078C15.5127 18.2699 17.0355 17.0202 18.1068 15.4168C19.1782 13.8134 19.75 11.9284 19.75 10C19.7473 7.41498 18.7192 4.93661 16.8913 3.10872C15.0634 1.28084 12.585 0.25273 10 0.25ZM7.52782 13.75H12.4722C11.9688 15.4694 11.125 17.0191 10 18.2397C8.875 17.0191 8.03125 15.4694 7.52782 13.75ZM7.1875 12.25C6.93876 10.7603 6.93876 9.23969 7.1875 7.75H12.8125C13.0613 9.23969 13.0613 10.7603 12.8125 12.25H7.1875ZM1.75 10C1.74935 9.23916 1.85442 8.48192 2.06219 7.75H5.66782C5.44407 9.24166 5.44407 10.7583 5.66782 12.25H2.06219C1.85442 11.5181 1.74935 10.7608 1.75 10ZM12.4722 6.25H7.52782C8.03125 4.53062 8.875 2.98094 10 1.76031C11.125 2.98094 11.9688 4.53062 12.4722 6.25ZM14.3322 7.75H17.9378C18.3541 9.22112 18.3541 10.7789 17.9378 12.25H14.3322C14.5559 10.7583 14.5559 9.24166 14.3322 7.75ZM17.3472 6.25H14.0256C13.6429 4.74392 13.0001 3.31623 12.1263 2.03125C13.2427 2.33127 14.2839 2.86162 15.1831 3.58818C16.0823 4.31475 16.8194 5.22146 17.3472 6.25ZM7.87375 2.03125C6.9999 3.31623 6.35713 4.74392 5.97438 6.25H2.65282C3.18056 5.22146 3.91772 4.31475 4.81689 3.58818C5.71606 2.86162 6.75733 2.33127 7.87375 2.03125ZM2.65282 13.75H5.97438C6.35713 15.2561 6.9999 16.6838 7.87375 17.9688C6.75733 17.6687 5.71606 17.1384 4.81689 16.4118C3.91772 15.6852 3.18056 14.7785 2.65282 13.75ZM12.1263 17.9688C13.0001 16.6838 13.6429 15.2561 14.0256 13.75H17.3472C16.8194 14.7785 16.0823 15.6852 15.1831 16.4118C14.2839 17.1384 13.2427 17.6687 12.1263 17.9688Z" fill="white"/>
        </svg>
        <span className='text-[16px] text-white font-bold'>{this.getSelectedLang(lang_id, languages)}</span>
        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.031 1.53062L9.53104 9.03061C9.46139 9.10035 9.37867 9.15567 9.28762 9.19341C9.19657 9.23115 9.09898 9.25058 9.00042 9.25058C8.90186 9.25058 8.80426 9.23115 8.71321 9.19341C8.62216 9.15567 8.53945 9.10035 8.46979 9.03061L0.969792 1.53062C0.829062 1.38988 0.75 1.19901 0.75 0.99999C0.75 0.800967 0.829062 0.610095 0.969792 0.469364C1.11052 0.328634 1.30139 0.249573 1.50042 0.249573C1.69944 0.249573 1.89031 0.328634 2.03104 0.469364L9.00042 7.43968L15.9698 0.469364C16.0395 0.399682 16.1222 0.344406 16.2132 0.306695C16.3043 0.268983 16.4019 0.249573 16.5004 0.249573C16.599 0.249573 16.6965 0.268983 16.7876 0.306695C16.8786 0.344406 16.9614 0.399682 17.031 0.469364C17.1007 0.539047 17.156 0.621773 17.1937 0.712817C17.2314 0.803862 17.2508 0.901444 17.2508 0.99999C17.2508 1.09854 17.2314 1.19612 17.1937 1.28716C17.156 1.37821 17.1007 1.46093 17.031 1.53062Z" fill="white"/>
        </svg>

       </div>
       {
        visible?
        <div className='bg-white shadow rounded-[8px] p-[10px] absolute w-full mt-[10px] gap-[10px] flex flex-col max-w-[100px]'>
            {
                languages?.map((data, i)=>{
                    return(
                        <button key={i} onClick={this.changeLangBtn.bind(this, data)}>{data?.title}</button>
                    )
                })
            }
        </div>:null
       }
       
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang_id: state.Data.lang_id
});
const mapDispatchToProps = {getlng, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ChangeLang)
