import React, { Component } from 'react'
import Card3 from '../cards/Card3'
import { getProjects } from '../../actions/MainAction';
import { connect } from 'react-redux';
export class Projects extends Component {
  componentDidMount(){
    this.props.getProjects(this.props.lang_id);
  }
  render() {
    const {projectsData} = this.props;

    return (
        <div className='grid grid-cols-3 mt-[52px]  max-[960px]:grid-cols-2 max-[670px]:grid-cols-1  max-[670px]:gap-[16px] gap-[64px] pt-[32px]'>
          {
            projectsData?.map((data, i)=>{
              if(i<3){
                return(
                  <Card3 key={i} data={data} />
                )
              }
            })
          }
        </div>
    )
  }
}

const mapStateToProps = (state)=>({
  projectsData: state.Data.projectsData,
  lang_id: state.Data.lang_id,
});
const mapDispatchToProps = {getProjects}
export default connect(mapStateToProps, mapDispatchToProps)(Projects)