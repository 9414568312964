import React, { Component } from 'react'

export class Card11 extends Component {
  render() {
    const {video} = this.props
    return (
        <div className='flex flex-col  max-[670px]:items-center gap-[24px]' >
            <div>
            <video controls src={video} className='w-[336px] h-[236px] rounded-[4px]' />
            </div>
      </div>
    )
  }
}

export default Card11